import { library, dom } from "@fortawesome/fontawesome-svg-core";
import {
	faMagnifyingGlass,
	faCaretDown,
	faUsers,
	faShip,
	faMapMarker,
	faXmark,
	faPlane,
	faLocationDot,
	faAnglesRight,
	faEarthEurope,
	faFlagCheckered,
	faBed,
	faUtensils,
	faPlaneDeparture,
	faPlaneArrival,
	faSpinner,
	faCheck,
	faMapLocationDot,
	faCircleInfo,
	faEllipsisVertical,
	faAt,
	faPhone,
	faMobileScreen,
	faSuitcase,
	faStar,
	faInfo,
	faDollarSign,
	faPercent,
	faTrashCan as fasTrashCan,
	faBars,
	faPhoneAlt,
	faCalendarCheck as fasCalendarCheck,
	faMapMarkerAlt,
	faEnvelope,
	faClock as fasClock,
	faPhoneAlt as fasPhoneAlt,
	faVideo,
	faMinus,
	faLock,
	faComments,
	faCookieBite,
	faRightToBracket,
	faPersonHiking,
	faUpRightFromSquare,
	faAnchorCircleCheck,
	faAnchorLock,
	faRightFromBracket,
	faCircleExclamation,
	faMoon
} from "@fortawesome/free-solid-svg-icons";
import {
	faClock,
	faCalendarCheck,
	faFlag,
	faBookmark,
	faFaceDizzy,
	faTrashCan as farTrashCan,
	faCompass,
	faCalendar,
	faCircleXmark,
	faUser,
	faBell,
	faSun
} from "@fortawesome/free-regular-svg-icons";
import {
	faWhatsapp,
	faFacebookF,
	faInstagram,
	faYoutube,
	faFacebookMessenger,
	faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";

if (document.body.dataset.noFa === undefined) {
	library.add(
		faMagnifyingGlass,
		faCaretDown,
		faUsers,
		faShip,
		faMapMarker,
		faXmark,
		faPlane,
		faLocationDot,
		faAnglesRight,
		faEarthEurope,
		faClock,
		faFlagCheckered,
		faCalendarCheck,
		faFlag,
		faBed,
		faUtensils,
		faPlaneDeparture,
		faPlaneArrival,
		faBookmark,
		faSpinner,
		faCheck,
		faMapLocationDot,
		faCircleInfo,
		faEllipsisVertical,
		faAt,
		faPhone,
		faMobileScreen,
		faSuitcase,
		faStar,
		faInfo,
		faDollarSign,
		faPercent,
		faFaceDizzy,
		fasTrashCan,
		farTrashCan,
		faMinus,
		faCookieBite,
		faCircleExclamation,
		// galeria
		faBars,
		faPhoneAlt,
		fasCalendarCheck,
		faMapMarkerAlt,
		faEnvelope,
		fasClock,
		fasPhoneAlt,
		faVideo,
		faWhatsapp,
		faFacebookF,
		faInstagram,
		faYoutube,
		// end
		// ferienhelden
		faLock,
		faComments,
		// end
		faRightToBracket,
		faPersonHiking,
		faUpRightFromSquare,
		faCompass,
		faCalendar,
		faFacebookMessenger,
		faCircleXmark,
		faAnchorCircleCheck,
		faAnchorLock,
		faRightFromBracket,
		faUser,
		faBell,
		faFacebookSquare,
		faMoon,
		faSun
	);

	dom.watch();
}
